import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

export const ContactUsButton = () => {
    return (
        <>
            <a className="cta" href="#contact" onClick={() => scrollTo('#contactForm')}>Contact Us</a>
        </>
    );
};

